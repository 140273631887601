.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 60px;
    position: sticky;
    position: -webkit-sticky;
    top: 0;
    background-color: #222425;
    color: #cdcdce;
    font-size: 2rem;
    padding: 10px;
    z-index: 2;
}

.appName {
    padding-left: 30px;
}

.appName>a {
    text-decoration: none;
    color: #cdcdce;
    cursor: pointer;
}

.companyLogoContainer {
    height: 100%;
}

.companyLogo {
    height: 100%;
}

.mainContent {
    display: flex;
    flex: 1;
    padding: 35px 20px;
    flex-direction: column;
    overflow-y: auto;
}

.pageHeading {
    color: #467abd; 
    margin-top: 0; 
    text-decoration: underline;
}

.container {
    width: 100%;
    display: flex;
    justify-content: start;
    align-items: center;
    flex-direction: column;
    flex: 1;
}

.footer {
    height: 40px;
    background-color: #222425;
    color: #cdcdce;
    font-size: 1.2rem;
    padding: 10px;
    padding-left: 40px;
}

@media screen and (max-width: 768px) {
    .appName {
        padding-left: 10px;
    }
}

@media screen and (max-width: 480px) {
    .header {
        font-size: 1rem;
    }

    .companyLogoContainer {
        width: 60%;
    }

    .companyLogo {
        width: 100%;
    }

    .footer {
        font-size: 1rem;
        padding-left: 10px;
        justify-content: center;
    }
}