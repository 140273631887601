.customerDetails .consent,
.customerDetailsOneColumn .consent {
    margin-top: 30px;
    margin-bottom: 20px;
}

.customerDetails{
    width: 100%;
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    /* align-items: flex-start; */
}

.customerDetailsOneColumn {
    width: 100%;
    display: flex;
    flex-flow: column wrap;
    justify-content: space-between;
    align-items: flex-start;
}

.customerInfo {
    width: 40%;
    padding: 0 5%;
    /* flex: 1; */
}

.customerInfoOneColumn {
    width: 40%;
    padding: 0 12px;
    margin-bottom: 10px;
    /* flex: 1; */
}

.paymentInfoParentContainer{
    width: 50%;
    padding: 0 5%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
}

.paymentInfoParentContainerOneColumn {
    width: 50%;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
}

.paymentInfoContainer{
    width: 100%
}

.paymentInfoContainer.error{
    border: 1px solid #a4262c;
}

.errorMessageContainer{
    align-self: flex-start;
}

.paymentGatewayIframe{
    height: 100%;
    width: 100%;
    border: none;
}

.waitingSpinner div[class*='ms-Dialog-main'] {
    min-height: auto;
}


@media (max-width: 1024px) and (min-width: 768px){
    .customerInfo{
        width: 40%;
    }
    .paymentInfoContainer{
        width: 60%;
    }
}

@media (max-width: 767px){
    .customerInfo{
        width: 100%;
        /* padding-right: 0; */
        margin-bottom: 27px;
        /* flex: 0 1 100%; */
    }

    .paymentInfoContainer{
        width: 100%;
        /* padding-left: 0; */
        /* flex: 0 1 100%; */
    }

    .customerInfoOneColumn {
        width: 100%;
    }

    .paymentInfoParentContainerOneColumn {
        width: 100%;
    }
}