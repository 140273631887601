@font-face {
    font-display: swap;
    font-family: "Webfont";
    font-style: normal;
    font-weight: 400;
    src: url(./fonts/TheSans_LP4_SemiLight.v_202003121200.woff) format("woff");
}

@font-face {
    font-family: "Webfont";
    font-style: normal;
    font-weight: 700;
    src: url(./fonts/TheSans_LP7_Bold.v_202003121200.woff) format("woff");
    font-display: swap;
}

* {
    box-sizing: border-box;
    font-family: Webfont;
}

body {
    margin: 0;
    padding: 0;
    display: block;
    font-size: 16px;
}

#root {
    width: 100%;
    display: flex;
    flex-direction: column;
    min-height: 100vh;
}

.row {
    width: 100%;
}

.row .label {
    width: 100%;
}

.row .field {
    width: 100%;
}

.width100 {
    width: 100%;
}

.commandBar{
    margin-top: 30px;
    width: 100%;
    padding: 0 6% 0 5%;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
}

.commandBarOneColumn {
    width: 50%;
    margin-top: 30px;
    padding: 0 12px;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
}

.sumitButton {
    padding-left: 30px;
    padding-right: 30px;
    background-color: #467abd;
    color: white;
    display: flex;
    justify-content: center;
}

.sumitButton:hover {
    background-color: #5b88c4
}

.errorMessage{
    color: #a4262c;
    font-size: 12px;
    padding-top: 5px;
}

.successMessage{
    color: green;
    border: 4px solid #5b88c4;
    padding: 10px;
    border-radius: 12px;
    background: #fcfdff;
}

.invalidPaymentLinkMessage{
    color: #a4262c;
    border: 4px solid #5b88c4;
    padding: 10px;
    border-radius: 12px;
    background: #fcfdff;
}

@media (max-width: 1000px) {
    .row .label {
        width: 100%;
    }

    .row .field {
        width: 100%;
    }
}

@media (max-width: 1000px) and (min-width: 768px) {
    .sumitButton {
        width: 30%;
    }
}

@media (max-width: 767px) {
    .commandBar{
        flex-direction: column;
        justify-content: center;
    }

    .commandBarOneColumn {
        width: 100%;
    }
}